import axios from "axios";
var omniApiUrlPrefix = process.env.VUE_APP_OMNI_BASE_URL;
var securityUrlPrefix = process.env.VUE_APP_OMNI_SECURITY_BASE_URL;

export function getUser(data) {
  return axios
    .get(securityUrlPrefix + "/api/identity/getUser", {
      params: {
        userId: data
      }
    })
    .then(response => {
      return response.data;
    })
    .catch(error => {
      return { success: false, data: error };
    });
}
export async function sendFeedbackEmail(data) {
  return axios
    .post(omniApiUrlPrefix + "/api/shared/sendFeedbackEmail", data)
    .then(response => {
      return response;
    })
    .catch(error => {
      return error;
    });
}

export function getClientsForUser(data) {
  return axios
    .get(securityApiUrlPrefix + "/api/identity/getClientsForUser", {
      params: {
        userName: data
      }
    })
    .then(response => {
      return response.data;
    })
    .catch(error => {
      return { success: false, data: error };
    });
}

export async function closeBatch(id) {
  return axios
    .post(omniApiUrlPrefix + "/api/rFAImport/closeBatch", null, {
      params: {
        batchId: id
      }
    })
    .then(response => {
      return response.data;
    })
    .catch(error => {
      return error;
    });
}

export async function sendEmails(payload) {
  return axios
    .post(omniApiUrlPrefix + "/api/rFAImport/sendEmails", payload)
    .then(response => {
      return response.data;
    })
    .catch(error => {
      return error;
    });
}

export async function updateComments(payload) {
  return axios
    .post(omniApiUrlPrefix + "/api/rFAImport/updateComments", null, {
      params: {
        id: payload.id,
        value: payload.value
      }
    })
    .then(response => {
      return response.data;
    })
    .catch(error => {
      return error;
    });
}

export async function updateAction(payload) {
  console.log("in the api");
  console.log(payload);
  return axios
    .post(omniApiUrlPrefix + "/api/rFAImport/updateAction", null, {
      params: {
        rowId: payload.rowId,
        providerId: payload.providerId,
        actionId: payload.actionId,
        initial: payload.initial
      }
    })
    .then(response => {
      return response.data;
    })
    .catch(error => {
      return error;
    });
}

export async function updateProvider(payload) {
  return axios
    .post(omniApiUrlPrefix + "/api/rFAImport/updateProvider", null, {
      params: {
        id: payload.id,
        value: payload.value
      }
    })
    .then(response => {
      return response.data;
    })
    .catch(error => {
      return error;
    });
}

export async function updateVAction(payload) {
  return axios
    .post(omniApiUrlPrefix + "/api/rFAImport/updateVAction", null, {
      params: {
        rowId: payload.rowId,
        actionId: payload.actionId
      }
    })
    .then(response => {
      return response.data;
    })
    .catch(error => {
      return error;
    });
}

export async function updateEvalDueDate(payload) {
  return axios
    .post(omniApiUrlPrefix + "/api/rFAImport/updateEvalDueDate", null, {
      params: {
        rowId: payload.rowId,
        actionId: payload.actionId,
        evalDueDate: payload.evalDueDate
      }
    })
    .then(response => {
      return response.data;
    })
    .catch(error => {
      return error;
    });
}
export async function updateHasConsent(payload) {
  return axios
    .post(omniApiUrlPrefix + "/api/rFAImport/updateHasConsent", null, {
      params: {
        id: payload.id,
        value: payload.value
      }
    })
    .then(response => {
      return response.data;
    })
    .catch(error => {
      return error;
    });
}

export async function getRfaBatches() {
  return axios
    .get(omniApiUrlPrefix + "/api/rFAImport/getAllBatches")
    .then(response => {
      console.log(response);
      return response.data;
    })
    .catch(error => {
      console.log("error" + error);
      return error;
    });
}

export async function getBatchRows(payload) {
  return axios
    .get(omniApiUrlPrefix + "/api/rFAImport/getBatchRows", {
      params: {
        batchId: payload
      }
    })
    .then(response => {
      console.log(response);
      return response.data;
    })
    .catch(error => {
      console.log("error" + error);
      return error;
    });
}

export async function getBatchProviders(payload) {
  console.log("In the api call");
  return axios
    .get(omniApiUrlPrefix + "/api/rFAImport/getProvidersForBatch", {
      params: {
        batchId: payload
      }
    })
    .then(response => {
      console.log(response);
      return response.data;
    })
    .catch(error => {
      console.log("error" + error);
      return error;
    });
}

export async function getMissingConsentReport(payload) {
  return axios
    .get(omniApiUrlPrefix + "/api/rFAImport/getMissingConsentReport", {
      params: {
        startDate: payload
      }
    })
    .then(response => {
      console.log(response);
      return response.data;
    })
    .catch(error => {
      console.log("error" + error);
      return error;
    });
}

export async function getOptions() {
  return axios
    .get(omniApiUrlPrefix + "/api/rFAImport/getOptions")
    .then(response => {
      return response.data;
    })
    .catch(error => {
      console.log("error" + error);
      return error;
    });
}

export async function getDistinctBatchProviders(payload) {
  console.log("In the api call");
  return axios
    .get(omniApiUrlPrefix + "/api/rFAImport/getDistinctProvidersForBatch", {
      params: {
        batchId: payload
      }
    })
    .then(response => {
      console.log(response);
      return response.data;
    })
    .catch(error => {
      console.log("error" + error);
      return error;
    });
}

export async function createRfa() {
  return axios
    .post(omniApiUrlPrefix + "/api/rFAImport/createImportBatch")
    .then(response => {
      console.log(response);
      return response.data;
    })
    .catch(error => {
      console.log("error" + error);
      return error;
    });
}



export async function uploadBilling(payload)
{
  return axios
    .post(omniApiUrlPrefix + "/api/evaluation/uploadBilling", payload)
    .then(response =>
    {
      console.log(response);
      return response.data;
    })
    .catch(error =>
    {
      console.log("error" + error);
      return error;
    });
}
export async function cancelBilling() {
  return axios
    .post(omniApiUrlPrefix + "/api/evaluation/cancelUploadBilling")
    .then(response => {
      return response.data;
    })
    .catch(error => {
      return error;
    });
}
export async function processExtensions(payload)
{
  return axios
    .post(omniApiUrlPrefix + "/api/evaluation/processExtensions", payload)
    .then(response =>
    {
      console.log(response);
      return response.data;
    })
    .catch(error =>
    {
      console.log("error" + error);
      return error;
    });
}




