<template>
  <div>
    <v-row>
      <label> Upload the .csv file containing the billing report. </label>
    </v-row>
    <v-row>
      <v-col cols="5">
        <template>
          <v-file-input
            v-model="selectedFile"
            accept=".csv"
            label="Upload file"
            color="#1c344b"
          ></v-file-input>
        </template>
      </v-col>

      <v-col cols="2">
        <v-btn
          small
          @click="uploadBilling"
          :disabled="!Boolean(selectedFile) || loading"
          :dark="Boolean(selectedFile) && !loading"
          class="mt-5"
          color="#1c344b"
          >Process Billing File</v-btn
        >
      </v-col>
      <v-col cols="2">
        <v-btn
          small
          outlined
          text
          @click="cancelBilling"
          :disabled="!loading"
          :dark="loading"
          class="mt-5"
          color="#1c344b"
          >Cancel</v-btn
        >
      </v-col>
    </v-row>
  </div>
</template>

<script>
export default {
  data() {
    return {
      selectedFile: null,
      loading: null,
    };
  },
  methods: {
    uploadBilling: async function () {
      this.loading = true;
      await this.$store.dispatch("evaluationsModule/uploadBilling", {
        file: this.selectedFile,
      });
      this.loading = false;
    },
    cancelBilling: async function () {
      await this.$store.dispatch("evaluationsModule/cancelBilling");
    },
  },
};
</script>
