import * as api from "../Services/api.js";
export const state = {};

export const mutations = {};
export const getters = {};
export const actions = {
  async uploadBilling({}, data) {
    let formData = new FormData();
    formData.append("listFile", data.file, data.file.name);

    var response = await api.uploadBilling(formData);

    var hiddenElement = document.createElement("a");
    hiddenElement.href = "data:text/csv;charset=utf-8," + encodeURI(response);
    hiddenElement.target = "_blank";
    hiddenElement.download = "billingData.csv";
    hiddenElement.click();
  },
  async cancelBilling({ commit }) {
    commit("uxModule/setLoaderMsg", "Cancelling...", {
      root: true,
    });
    commit("uxModule/setShowLoader", true, {
      root: true,
    });

    var response = await api.cancelBilling();
    if (response) {
      commit(
        "uxModule/setSnackbarMsg",
        "Cancelled. Please wait for the result file to download before trying again.",
        {
          root: true,
        }
      );
      commit("uxModule/setShowSnackbar", true, {
        root: true,
      });
    }
    commit("uxModule/setShowLoader", false, {
      root: true,
    });
  },
  async processExtensions({ commit }, data) {
    commit("uxModule/setShowLoader", true, {
      root: true,
    });
    let formData = new FormData();
    formData.append("listFile", data.file, data.file.name);

    var response = await api.processExtensions(formData);

    var hiddenElement = document.createElement("a");
    hiddenElement.href = "data:text/csv;charset=utf-8," + encodeURI(response);
    hiddenElement.target = "_blank";
    hiddenElement.download = "extensionsData.csv";
    hiddenElement.click();
    commit("uxModule/setShowLoader", false, {
      root: true,
    });
  },
  async downloadTemplate({ commit }) {
    commit("uxModule/setShowLoader", true, {
      root: true,
    });
    var data =
      "Last Name,First Name,OSIS,DOB,Service,Lang,Dist,ActionID,ProvID,Prov Last,Prov First,Report Due Date,Extended Due,Appointment Date,Days to extend";

    var hiddenElement = document.createElement("a");
    hiddenElement.href = "data:text/csv;charset=utf-8," + encodeURI(data);
    hiddenElement.target = "_blank";
    hiddenElement.download = "ExtensionList.csv";
    hiddenElement.click();
    commit("uxModule/setShowLoader", false, {
      root: true,
    });
  },
};

export default {
  namespaced: true,
  state,
  mutations,
  getters,
  actions,
};
