<template>
  <div>
    <v-row>
      <label>
        Upload the .csv file containing the list of extensions The column
        headers should be: Last Name,First
        Name,OSIS,DOB,Service,Lang,Dist,ActionID,ProvID,Prov Last,Prov
        First,Report Due Date,Extended Due,Appointment Date,Days to extend. To
        download a template, click
        <button style="color: teal" v-on:click="downloadTemplate">here</button>
      </label>
    </v-row>
    <v-row>
      <v-col cols="6">
        <template>
          <v-file-input
            v-model="selectedFile"
            accept=".csv"
            label="Upload file"
            color="#1c344b"
          ></v-file-input>
        </template>
      </v-col>

      <v-col cols="2">
        <v-btn
          small
          @click="processExtensions"
          :disabled="!Boolean(selectedFile)"
          :dark="Boolean(selectedFile)"
          class="mt-5"
          color="#1c344b"
          >Process Extensions File</v-btn
        >
      </v-col>
    </v-row>
  </div>
</template>

<script>
export default {
  data() {
    return {
      selectedFile: null,
    };
  },
  methods: {
    processExtensions: async function () {
      await this.$store.dispatch("evaluationsModule/processExtensions", {
        file: this.selectedFile,
      });
    },
    downloadTemplate: async function () {
      await this.$store.dispatch("evaluationsModule/downloadTemplate");
    },
  },
};
</script>
